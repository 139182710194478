import { Link } from 'react-router-dom';


export default function Footer(){

    const firstGrid = [
        {
            imagePath: "./images/twitter-social.png",
            url: "https://twitter.com/FridaKNFT",
        },
        {
            imagePath: "./images/instagram-social.png",
            url: "https://www.instagram.com/frida.nft/",
        },
        {
            imagePath: "./images/discord-social.png",
            url: "https://discord.com/invite/39ACHQ6dV2",
        },
        {
            imagePath: "./images/medium-social.png",
            url: "https://medium.com/@Frida.NFT",
        },
    ];

    const secondGrid = [
        {
            category: "About",
            url: ""
        },
        {
            category: "Careers",
            url: ""
        },
        {
            category: "Blog",
            url: ""
        },
        {
            category: "Contact us",
            url: "mailto:team@fridanft.org"
        },
    ];

    const thirdGrid = [
        {
            category: "FAQ",
            url: "",
            
        },
    ];

    const fourthGrid = [
        {
            category: "Help center",
            url: "mailto:team@fridanft.org"
        },
        {
            category: "Partners",
            url: ""
        },
        {
            category: "Subscribe",
            url: ""
        },
    ];

    const fiveGrid = [
        {
            category: "Whitepaper",
            url: "https://fridanft.gitbook.io/docs/"
        },
        {
            category: "Certificate of Authenticity",
            url: "https://fridanft.org/docs/Authentication_Cert.pdf"
        },
        {
            category: "Study of the Painting",
            url: "https://fridanft.org/docs/FridaKahlo_Fantasmones_English.pdf"
        },
        {
            category: "Estudio de Obra",
            url: "https://fridanft.org/docs/FridaKahlo_Fantasmones_Spanish.pdf"
        },
    ];

    return(
        <footer>
            <div className="container">
                <div className="background"></div>
                    <div className="grid columns-12">
                        <div className="sections">
                            <div className="first-grid">
                                <div className="logo">
                                <img alt="image" src={"./images/fridan-logo-purple.png"}></img>
                                </div>
                                <div className="socials" key="first.imagePath">
                                    {firstGrid.map((first)=> (
                                        <div className="social" key={first.imagePath}>
                                            <a href={first.url}><img src={first.imagePath}></img></a>
                                        </div>
                                    ))}
                                </div>
                            </div>


                            <div className="second-grid">
                                <h1 className="title"></h1>
                                <div className="alignament-grid">
                                    <ul>
                                        {secondGrid.map((second)=> (
                                            <li key={second.category}><a href={second.url}>{second.category}</a></li>
                                        ))}
                                    </ul>
                                    <ul>
                                        {thirdGrid.map((third)=> (
                                            <li key={third.category}><a href={third.url}>{third.category}</a></li>
                                        ))}
                                    <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                    <li><Link to='/terms-of-service'>Terms Of Service</Link></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="third-grid">
                                <h1 className="title"></h1>
                                <ul>
                                    {fourthGrid.map((fourth)=> (
                                        <li key={fourth.category}><a href={fourth.url}>{fourth.category}</a></li>
                                    ))}
                                </ul>
                            </div>
                            <div className="forth-grid">
                            <h1 className="title"></h1>
                                <ul>
                                    {fiveGrid.map((five) => (
                                        <li key={five.category}><a href={five.url}>{five.category}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="credits">
                            <p>Copyright © 2024  Frida.NFT. All rights reserved.</p>
                        </div>

                    </div>
            </div>
        </footer>
    )
}