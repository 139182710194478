import { connect, addEthereumChain, MetamaskInit, getMetaAccount } from './utils/metamask-functions';
import WC from '@walletconnect/client';
import { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import Header from './components/header';
import Contact from './components/contact';
import MemberBenefits from './components/memberBenefits';
import Discover from './components/discover';
import Gallery from './components/gallery';
import InfoLinks from './components/infoLinks';
import Blog from './components/blog';
import Footer from './components/footer';
import Roadmap from './components/roadmap';
import Faq from './components/faq';
import Mint from './components/mint';
import TermsOfService from './components/termsOfService';
import PrivacyPolicy from './components/privacyPolicy';
import Subscribe from './components/subscribe';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const tagManagerArgs = {
  gtmId: 'G-C7VLSW89TG'
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  function importAll(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const images = importAll(require.context('../public/images', false, /\.(png|jpe?g|svg)$/));
  const [account, setAccount] = useState(null);

  useEffect(() => {
    MetamaskInit();
    const interval = setInterval(() => {
      setAccount(getMetaAccount());
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <Router>
        <Header account={account} />
        <Routes>
          <Route exact path="/" element={(
            <>
              <Discover account={account} images={images} />
              <InfoLinks images={images} />
              <Roadmap images={images} />
              <Blog images={images} />
              <Faq images={images} />
              <Subscribe images={images} />
            </>
          )} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/meta-gallery" element={<MemberBenefits />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer images={images} />
      </Router>
    </div>
  );
}
