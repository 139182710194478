import { connect } from '../utils/metamask-functions';
import { Link } from 'react-router-dom';

export default function Header({ account }) {
    return (
        <header>
            <div className="container">
                <div className="logo">
                    <Link to='/'></Link>
                    <img alt="logo-image" src="./images/fridan-logo-purple.png" />
                </div>
                <div className="nav-menu">
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><a href="https://www.youtube.com/watch?v=_M23F73G0Jc" target="_blank" rel="noopener noreferrer">Event</a></li>
                        <li><Link to='/meta-gallery'>Meta Gallery</Link></li>
                        <li><Link to='/contact'>Contact</Link></li>
                    </ul>
                </div>
                <div className="button gradient" onClick={() => {
                    if (account == null) {
                        connect();
                    }
                }}>
                    {account == null ? (
                        <h4>Connect Wallet</h4>
                    ) : (
                        <Link to='/mint'><h4>Buy/Mint</h4></Link>
                    )}
                </div>
            </div>
        </header>
    );
}
