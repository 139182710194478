export default function PopupInsufficient({ onClose }) {
    return (
        <div className="popup-insufficient" style={{
            position: 'fixed',
            top: '10px',
            right: '10px',
            backgroundColor: '#f94449',
            color: '#2f2f2f',
            padding: '15px',
            borderRadius: '5px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
            zIndex: 1000
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ margin: 0, color: '#3f3f3f' }}>You have insufficient funds to complete this transaction.</p>
                <button 
                    onClick={onClose} 
                    style={{
                        marginLeft: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#721c24',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}
                >
                    X
                </button>
            </div>
        </div>
    );
}
