import { connect } from '../utils/metamask-functions';
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Discover({ account }) {
    const [buyOptionModal, setBuyOptionModal] = useState(false);
    const [creditCard, setCreditCard] = useState(false);

    return (
        <section className="discover">
            <div className="container">
                <div className="background"></div>
                <div className="grid columns-12">
                    <div className="left">
                        <h1 className="title" style={{ fontSize: "60px" }}>
                            Explore Frida Kahlo's Digital "Fantasmones" Collection to Support
                            Charities Worldwide
                        </h1>
                        <p>
                            This is the official site of Frida Kahlo’s “Fantasmones” painting
                            burn and the creation of the digital “Fantasmones” collection
                            developed to empower charities around the world.
                        </p>
                        <div className="numbers">
                            <div className="box">
                                <div className="number">300</div>
                                <p>Genesis Founders</p>
                            </div>
                            <div className="box">
                                <div className="number">1000</div>
                                <p>Deltas</p>
                            </div>
                            <div className="box">
                                <div className="number">8700</div>
                                <p>Enamorates</p>
                            </div>
                        </div>
                        <div className="buttons">
                            <div className="button black">
                                <a href="https://fridanft.gitbook.io/docs/">Whitepaper</a>
                            </div>
                            <div
                                className="button gradient buy-nft"
                                onClick={() => {
                                    setBuyOptionModal(true);
                                }}
                            >
                                <h4>Buy NFT</h4>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="card" style={{ height: "300px", minHeight: 0 }}>
                            <div className="background-card"></div>
                            <div className="content" style={{ height: "100%" }}>
                                <div className="image" style={{ height: "100%" }}>
                                    <img
                                        alt="image"
                                        src="./images/nft-background.gif"
                                        style={{ height: "100%" }}
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    {buyOptionModal === true && (
                        <div className="buy-option-modal">
                            <div
                                className="buy-option-close-button"
                                onClick={() => {
                                    setBuyOptionModal(false);
                                }}
                            >
                                <img src="../images/cross-white.svg"></img>
                            </div>
                            <div
                                className="button gradient"
                                onClick={() => {
                                    if (account == null) {
                                        connect();
                                    }
                                }}
                            >
                                {account == null ? (
                                    <h4>Pay with ETH (not connected)</h4>
                                ) : (
                                    <Link to="/mint">Pay with ETH</Link>
                                )}
                            </div>

                            <div
                                className="button gradient credit-card"
                                onClick={() => {
                                    setCreditCard(true);
                                }}
                            >
                                <h4>Pay with Credit Card</h4>
                            </div>

                            {creditCard && (
                                <div className="creditcard-container">
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            setCreditCard(false);
                                        }}
                                    >
                                        <img src="./images/cross.svg"></img>
                                    </div>
                                    <iframe
                                        className="creditcard"
                                        height="550"
                                        src="https://payments.nftpay.xyz/iframe/iframe_pay/e40a5b42-d71c-42cf-9edc-7ba361829030?"
                                    ></iframe>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}
