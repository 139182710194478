import { ethers } from "ethers";
import FridaABI from "./FridaAbi";

let meta_account = null;
let meta_chain = null;

const connect = async () => {
    try {
        const accounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
        });
        console.log('Connected accounts:', accounts);
        meta_account = accounts[0];
    } catch (error) {
        console.error('Error connecting to MetaMask:', error);
    }
};

const addEthereumChain = async () => {
    try {
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x89',
                    chainName: 'Polygon',
                    blockExplorerUrls: ['https://polygonscan.com'],
                    nativeCurrency: { symbol: 'MATIC', decimals: 18 },
                    rpcUrls: ['https://polygon-rpc.com/'],
                },
            ],
        });
        console.log('Polygon network added successfully.');
    } catch (error) {
        console.error('Error adding Polygon network:', error);
    }
};

const MetamaskInit = () => {
    if (!window.ethereum) {
        console.error("MetaMask is not installed. Please install MetaMask to use this feature.");
        return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

    provider.on("network", (newNetwork, oldNetwork) => {
        if (oldNetwork) {
            window.location.reload();
        }
    });

    window.ethereum.on('chainChanged', (chain) => {
        console.log('Chain changed to:', chain);
        meta_chain = chain;
    });

    window.ethereum.on('accountsChanged', (accounts) => {
        console.log('Accounts changed to:', accounts);
        meta_account = accounts[0] || null;
    });

    console.log("MetaMask initialized successfully.");
};

const sendTransaction = async (amount) => {
    try {
        if (!window.ethereum) throw new Error("MetaMask is not installed.");

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const fridaContractAddress = "0xA347D6A871E12725Ab615F79BfFF3C5F97DeB910";
        const fridaContract = new ethers.Contract(fridaContractAddress, FridaABI, signer);

        const mintFee = await fridaContract.mintFee();
        const totalValue = mintFee.mul(amount);

        const transaction = await fridaContract.mintNFT(amount, {
            value: totalValue,
        });

        const receipt = await transaction.wait();
        console.log("Transaction successful:", receipt);

    } catch (error) {
        throw error;
    }
};

const getMetaAccount = () => meta_account;

export {
    connect,
    addEthereumChain,
    MetamaskInit,
    sendTransaction,
    getMetaAccount,
};
